import { RootStore } from "@store/rootStore";
import React, { createContext, useContext } from "react";

export const RootStoreContext = createContext<RootStore | undefined>(undefined);

export function useRootStore(): RootStore {
  const rootStore = useContext(RootStoreContext);
  if (rootStore === undefined) {
    throw new Error("useRootStore must be used within a RootStoreProvider");
  }
  return rootStore;
}
